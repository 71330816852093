import { ImageModel } from "@/api/generated/BMWInterfaces"
import dynamic from "next/dynamic"
import Image from "next/image"
import { useEffect, useState } from "react"

const ReactPlayer = dynamic(() => import("react-player/lazy"), {
  ssr: false,
  loading: () => <div style={{ width: "100%", height: "100%" }} />,
})

type Props = {
  image: ImageModel
  index: number
  playing: boolean
  onReady: () => void
}

export const isVideo = (url: string): boolean => {
  return url.includes(".mp4") || url.includes("youtube")
}

const HeroMedia = ({ image, index, playing, onReady }: Props): JSX.Element => {
  const isMediaVideo = isVideo(image.imageUrl)

  const [isReady, setIsReady] = useState(false)

  const handleReady = (): void => {
    setIsReady(true)
    onReady()
  }

  useEffect(() => {
    if (!isMediaVideo) {
      handleReady()
    }
  }, [isMediaVideo])

  return isMediaVideo ? (
    <ReactPlayer
      controls={false}
      muted={true}
      loop
      playing={playing && isReady}
      url={image.imageUrl}
      width={"100%"}
      height={"100%"}
      playsinline={true}
      config={{
        file: {
          attributes: {
            autoPlay: playing,
            playsInline: true,
            muted: true,
            preload: "auto",
            style: { objectFit: "cover", width: "100%", height: "100%" },
          },
          forceVideo: true,
        },
      }}
      onReady={handleReady}
      style={{ display: "flex", position: "absolute", inset: 0 }}
    />
  ) : (
    <Image
      src={image.imageUrl}
      alt={image.alt || `story_${index}`}
      fill
      style={{ objectFit: "cover" }}
      priority
    />
  )
}

export default HeroMedia
