"use client"

import { Brand, HomePage, PriceRangeResponse } from "@/api/generated/BMWInterfaces"
import { pageview } from "@/lib/gtm"
import styled from "@emotion/styled"
import { useCallback, useEffect, useRef, useState } from "react"
import Advantages from "./Advantages"
import CarInterest from "./CarInterest"
import FAQComp from "./FAQComp"
import Hero from "./Hero/Hero"
import HeroDrop from "./HeroDrop"
import MoreOffers from "./MoreOffers"
import PremiumDeals from "./PremiumDeals"
import StickyButton from "./StickyButton"

const OutLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Layout = styled.section`
  padding-bottom: 32px;
  padding-top: 32px;
  gap: 32px;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 64px;
    padding-top: 64px;
    gap: 64px;
  }
  display: flex;
  flex-direction: column;
`

const InnerLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
type Props = {
  data: HomePage
  brands: Brand[]
  priceRange: PriceRangeResponse
}

export default function Homepage({ data, brands, priceRange }: Props): React.ReactElement {
  const [showSticky, setShowSticky] = useState(false)
  const heroRef = useRef<HTMLDivElement>(null)
  const afterHeroRef = useRef<HTMLDivElement>(null)
  const { faq, mainBanner, topModels } = data
  const { minPrice, maxPrice } = priceRange
  const handleStickyButtonClick = useCallback(() => {
    if (afterHeroRef.current) {
      const yOffset = -80 // Adjust this value to control how much higher it scrolls
      const element = afterHeroRef.current
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset
      window.scrollTo({ top: y, behavior: "smooth" })
    }
  }, [])
  useEffect(() => {
    const handleScroll = (): void => {
      if (heroRef.current) {
        const heroBottom = heroRef.current.getBoundingClientRect().bottom

        setShowSticky(heroBottom <= 350)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])
  useEffect(() => {
    pageview()
  }, [])

  return (
    <OutLayout>
      <section ref={heroRef}>
        <Hero
          handleStickyButtonClick={handleStickyButtonClick}
          data={data.mainBanner.heros.mainBannerHero}
        />
      </section>
      {showSticky && <StickyButton />}
      <Layout>
        <PremiumDeals data={topModels} />
        <InnerLayout ref={afterHeroRef}>
          <CarInterest data={brands} minPrice={minPrice} maxPrice={maxPrice} />
          <MoreOffers />
          <Advantages />
          {mainBanner.media.videoLink && <HeroDrop data={mainBanner.media} />}
        </InnerLayout>

        <FAQComp data={faq} />
      </Layout>
    </OutLayout>
  )
}
