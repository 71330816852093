export const utmParamKeys = ["utm", "gclid", "fbclid", "gad_source", "creative", "audiences"]

export const keepOnlyUtmParams = (search: string): string => {
  if (!search) return ""
  const params = search.split("&")
  return params
    .filter((param) => utmParamKeys.some((key) => param.includes(key)))
    .join("&")
    .replace(/^\?/, "")
}

export const getUtmParamsAsKeyValueList = (): {
  key: string
  value: string
}[] => {
  let search = keepOnlyUtmParams(window.location.search)
  if (!search) return []

  // remove the first character if it is (?)
  if (search[0] === "?") {
    search = search.slice(1)
  }

  const params = search.split("&")
  return params.map((param) => {
    const [key, value] = param.split("=")
    return { key, value: decodeURI(value || "") }
  })
}
