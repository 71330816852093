import { MainBannerHero } from "@/api/generated/BMWInterfaces"
import LinkWithParams from "@/lib/common/LinkWithParams"
import { Desktop, Mobile } from "@/lib/common/MediaQueries"
import { allOffersClick, upperFindCarClick } from "@/lib/gtm"
import styled from "@emotion/styled"
import { Button, Typography } from "@mui/material"
import Image from "next/image"

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  align-items: center;
  flex: 1 0 0;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 64px;
  }
`

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  text-align: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    flex: 1 0 0;
  }
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  text-align: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 4px;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    flex: 1 0 0;
  }
`

const ButtonsBox = styled.div<{ marginTop: boolean }>`
  display: flex;
  gap: 16px;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: ${({ marginTop }) => (marginTop ? "50px" : "0")};
    gap: 24px;
    max-width: 472px;
  }
`

const StyledLogoContainer = styled.div`
  height: 48px;
  width: 148px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 64px;
    width: 200px;
  }
`

type Props = {
  story: MainBannerHero
  handleStickyButtonClick: () => void
}

export default function HeroContent({ story, handleStickyButtonClick }: Props): React.ReactElement {
  const currentStory = story
  return (
    <StyledContent>
      <ContentBox>
        <StyledLogoContainer>
          {currentStory.logo && (
            <>
              <Desktop>
                <Image
                  src={currentStory.logo.image.imageUrl}
                  alt={currentStory.logo.image.alt || ""}
                  width={200}
                  height={64}
                  style={{ objectFit: "contain" }}
                  priority
                />
              </Desktop>
              <Mobile>
                <Image
                  src={currentStory.logo.image.imageUrl}
                  alt={currentStory.logo.image.alt || ""}
                  width={148}
                  height={48}
                  style={{ objectFit: "contain" }}
                  priority
                />
              </Mobile>
            </>
          )}
        </StyledLogoContainer>
        <TextBox>
          <Mobile>
            <Typography variant="h2" component={"h1"} color={"white"}>
              {currentStory.title}
            </Typography>
          </Mobile>
          <Mobile>
            <Typography variant="body1" component={"p"} color={"white"}>
              {currentStory.subTitle}
            </Typography>
          </Mobile>
          <Desktop>
            <Typography variant="h1" component={"h1"} color={"white"}>
              {currentStory.title}
            </Typography>
          </Desktop>
          <Desktop>
            <Typography variant="h3" component={"p"} color={"white"}>
              {currentStory.subTitle}
            </Typography>
          </Desktop>
        </TextBox>
      </ContentBox>
      <ButtonsBox marginTop={!currentStory.title && !currentStory.subTitle && !currentStory.logo}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          LinkComponent={LinkWithParams}
          href="/search"
          onClick={() => {
            upperFindCarClick()
          }}
        >
          לכל ההצעות
        </Button>
        <Button
          onClick={() => {
            allOffersClick()
            handleStickyButtonClick()
          }}
          variant="outlined"
          fullWidth
          // LinkComponent={LinkWithParams}
        >
          חיפוש חכם
        </Button>
      </ButtonsBox>
    </StyledContent>
  )
}
