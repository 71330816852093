"use client"

import LinkWithParams from "@/lib/common/LinkWithParams"
import { Desktop, Mobile } from "@/lib/common/MediaQueries"
import styled from "@emotion/styled"
import { Button, Typography } from "@mui/material"
import Image from "next/image"

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 93px 16px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    align-items: center;
    padding: 269px 50px 221px;
  }
`

const StyledImageContainer = styled.div`
  position: relative;
  height: 287px;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 310px;
    width: 460px;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    height: 650px;
    width: 920px;
  }
`

const OuterContentBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    align-items: flex-start;
    gap: 32px;
  }
`

const StyledTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up("md")} {
    align-items: flex-start;
  }
`

export default function ErrorPage({
  type,
}: {
  type: "not-found" | "internal-error"
}): React.ReactElement {
  return (
    <StyledLayout>
      <OuterContentBox>
        <StyledContentBox>
          {type === "not-found" && (
            <StyledTextBox>
              <Mobile>
                <Typography variant="h3">הכתובת לא הובילה לשום מקום</Typography>
              </Mobile>
              <Desktop>
                <Typography variant="h2">הכתובת לא הובילה לשום מקום</Typography>
              </Desktop>
              <Typography variant="body1">לאן רצית להגיע? נביא אותך לשם בבטחה</Typography>
            </StyledTextBox>
          )}
          {type === "internal-error" && (
            <StyledTextBox>
              <Mobile>
                <Typography variant="h3">משהו השתבש</Typography>
              </Mobile>
              <Desktop>
                <Typography variant="h2">משהו השתבש</Typography>
              </Desktop>
              <Typography variant="body1">לאן רצית להגיע? נביא אותך לשם בבטחה</Typography>
            </StyledTextBox>
          )}
          <Button variant="contained" LinkComponent={LinkWithParams} href="/">
            לעמוד בית
          </Button>
        </StyledContentBox>
      </OuterContentBox>
      <StyledImageContainer>
        <Image src={type === "not-found" ? "/images/404.png" : "/images/500.png"} alt={type} fill />
      </StyledImageContainer>
    </StyledLayout>
  )
}
