import { FaqDetails } from "@/api/generated/BMWInterfaces"
import LinkWithParams from "@/lib/common/LinkWithParams"
import { Desktop, Mobile } from "@/lib/common/MediaQueries"
import { faqButtonClick, homePageModelNotFoundClick, questionsClick } from "@/lib/gtm"
import styled from "@emotion/styled"
import { Button, Typography } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Image from "next/image"
import { useMemo, useState } from "react"

const FAQWrapper = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 675px;
  gap: 32px;
  padding-left: 16px;
  padding-right: 16px;
  scroll-margin-top: 100px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0;
    gap: 40px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 8px;
    text-align: center;
  }
`

const AccordionWrapper = styled.div``

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 24px;
  }
`

const CustomExpandIcon = ({ expanded }: { expanded: boolean }): JSX.Element => {
  return useMemo(
    () =>
      expanded ? (
        <Image src="/assets/minus.svg" alt="" width={25} height={25} />
      ) : (
        <Image src="/assets/add.svg" alt="" width={25} height={25} />
      ),
    [expanded],
  )
}

export default function FAQComp({ data }: { data: FaqDetails }): React.ReactElement {
  const [expanded, setExpanded] = useState<string | false>("panel1")
  const faq = data
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <FAQWrapper id="faq">
      <TextWrapper>
        <Mobile>
          <Typography variant="h3">כל מה שחשוב לדעת</Typography>
        </Mobile>
        <Desktop centerHorizontally>
          <Typography variant="h2">כל מה שחשוב לדעת</Typography>
        </Desktop>
        <Mobile>
          <Typography variant="body1">
            ריכזנו לכם את כל השאלות והתשובות שיעזרו לכם
            <br />
            לרכוש רכב בהזדמנות מיוחדת ובראש שקט
          </Typography>
        </Mobile>
        <Desktop centerHorizontally>
          <Typography variant="h3">
            ריכזנו לכם את כל השאלות והתשובות שיעזרו לכם
            <br />
            לרכוש רכב בהזדמנות מיוחדת ובראש שקט
          </Typography>
        </Desktop>
      </TextWrapper>
      <AccordionWrapper>
        {faq?.faqs?.map((faq) => (
          <Accordion
            key={faq.id}
            expanded={expanded === faq.name}
            onChange={(e, ex) => {
              handleChange(faq.name)(e, ex)
              questionsClick()
            }}
          >
            <AccordionSummary
              expandIcon={<CustomExpandIcon expanded={expanded === faq.name} />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <Typography variant="subtitle1">{faq.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  unicodeBidi: "plaintext", // Handles mixed RTL and LTR text correctly
                }}
                variant="body2"
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionWrapper>
      <ButtonsWrapper>
        <Button
          onClick={() => {
            homePageModelNotFoundClick()
          }}
          fullWidth
          variant="contained"
          size="medium"
          LinkComponent={LinkWithParams}
          href="/contact-us"
        >
          לא מצאת דגם?
        </Button>
        <Button
          onClick={() => {
            faqButtonClick()
          }}
          fullWidth
          variant="contained"
          size="medium"
          color="secondary"
          LinkComponent={LinkWithParams}
          href="/search"
        >
          לכל הרכבים
        </Button>
      </ButtonsWrapper>
    </FAQWrapper>
  )
}
