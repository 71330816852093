import LinkWithParams from "@/lib/common/LinkWithParams"
import { Desktop, Mobile } from "@/lib/common/MediaQueries"
import { whatLookingForClick } from "@/lib/gtm"
import styled from "@emotion/styled"
import { Button, Typography } from "@mui/material"
import Image from "next/image"

const StyledLayout = styled.div`
  display: flex;
  padding: 32px 16px;
  flex-direction: column;

  gap: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 64px 40px;
    align-items: center;
    gap: 40px;
  }
`

const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    text-align: center;
  }
`

const ButtonContainer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    max-width: 328px;
    width: 100%;
  }
`

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: 120px;
  ${(props) => props.theme.breakpoints.up("md")} {
    max-width: 1600px;
    height: 280px;
  }
`

export default function MoreOffers(): React.ReactElement {
  return (
    <StyledLayout>
      <Desktop>
        <HeaderBox>
          <Typography variant="h2">אתם יודעים הכי טוב מה אתם רוצים</Typography>
          <Typography variant="h3">
            ספרו לנו בדיוק מה אתם מחפשים וברגע שתצוץ
            <br />
            הזדמנות חדשה ומתאימה, אתם תהיו הראשונים לשמוע עליה
          </Typography>
        </HeaderBox>
      </Desktop>
      <Mobile>
        <HeaderBox>
          <Typography variant="h3">אתם יודעים הכי טוב מה אתם רוצים</Typography>
          <Typography variant="body1">
            ספרו לנו בדיוק מה אתם מחפשים וברגע שתצוץ
            <br />
            הזדמנות חדשה ומתאימה, אתם תהיו הראשונים לשמוע עליה
          </Typography>
        </HeaderBox>
      </Mobile>
      <CarouselContainer>
        <Image
          alt="moreoffers"
          fill
          style={{ objectFit: "cover" }}
          src={"/images/carouselDesktopNew.png"}
        />
      </CarouselContainer>
      <ButtonContainer>
        <Button
          onClick={() => {
            whatLookingForClick()
          }}
          variant="contained"
          color="primary"
          href="/contact-us"
          LinkComponent={LinkWithParams}
          fullWidth
        >
          ספרו לנו
          {" >>"}
        </Button>
      </ButtonContainer>
    </StyledLayout>
  )
}
